<template>
    <div class="form-box">
        <div class="form-h1">{{ lang('Access recovery') }}</div>
        <form class="form" @submit.prevent="formSubmit($event)">
            <div v-if="action_form === 'show-pass'">
                <div class="form__input" @click="formInputFocus($event)">
                    <input type="password" name="password" @focus="formInputFocus($event, true)"  @blur="formInputBlur($event)" />
                    <span>{{ lang('New password') }}</span>
                </div>
                <div class="form__input" @click="formInputFocus($event)">
                    <input type="password" name="password_confirm" @focus="formInputFocus($event, true)"  @blur="formInputBlur($event)" />
                    <span>{{ lang('New password confirmation') }}</span>
                </div>
            </div>

            <div v-else-if="action_form === 'show-code'">
                <div class="form__input" @click="formInputFocus($event)">
                    <input type="text" name="confirm_code" autocomplete="off" @input="formInputUseDigital($event)" @focus="formInputFocus($event, true)"  @blur="formInputBlur($event)" />
                    <span>{{ lang('Confirmation code') }}</span>
                </div>
                <div v-if="!is_resend_code" class="form__timer" v-html="lang('You can get a new code in N seconds', {timerSecond: confirm_time, wordSecond: cfrm_time_text})"></div>
                <button v-else type="button" @click="reGetCodeConfirm()" class="form__timer-btn">{{ lang('Send code') }}</button>
            </div>

            <div v-else-if="action_form === 'show-final'">
                <div class="form__success">
                    {{ lang('Password has been successfully changed') }}
                </div>
            </div>

            <div v-else>
                <div class="form__input" @click="formInputFocus($event)">
                    <input type="text" name="email" @focus="formInputFocus($event, true)"  @blur="formInputBlur($event)" />
                    <span>{{ lang('E-mail') }}</span>
                </div>
            </div>

            <div :class="'form_messages ' + message_toggle">
                <ul>
                    <li v-for="item in messages" :key="Object.keys(item)[0]">{{ Object.values(item)[0] }}</li>
                </ul>
            </div>

            <div :class="(action_form === 'show-final') ? 'form__buttons form__buttons--flex-center' : 'form__buttons'">
                <router-link to="/auth" custom v-slot="{ navigate, href }">
                    <a :href="href" @click="navigate" class="btn-sigin">
                        {{ (action_form === 'show-final') ? lang('Sign in account') : lang('Sign in') }}
                    </a>
                </router-link>

                <ButtonForms :is_show="is_loading"  v-if="action_form === 'show-pass'">{{ lang('Update password') }}</ButtonForms>
                <ButtonForms :is_show="is_loading"  v-else-if="action_form === 'show-code'">{{ lang('Check code') }}</ButtonForms>
                <ButtonForms :is_show="is_loading" v-show="action_form != 'show-final'"  v-else>{{ lang('Send') }}</ButtonForms>
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios'

import { ReqUrls } from '@/requstes'
import { Lang } from '@/lang'

export default {
    name: 'FormForgotPswd',
    props: ['action'],
    inject: [
        'formInputFocus',
        'formInputBlur',
        'timerUpdate',
        'declension_words',
        'messagesPrint',
        'getCodeConfirm',
        'formInputUseDigital',
        'getCurrentTimer'
    ],
    watch: {
        liveTimeConfrimCode(val, oldVal) {
            const uData = JSON.parse(localStorage.getItem('confirm_email')) || {email: '', time: parseInt(Date.now() / 1000) - 300}
            const diffTime = parseInt(val) - parseInt(uData.time)

            if (diffTime >= 300) {
                this.confirm_time = 60
                this.action_form = ''
                this.inputs.password = ''
                this.inputs.password_confirm = ''
                this.inputs.confirm_code = ''
                this.messages = []
                this.$router.push({ path: '/forgotpswd' })

                localStorage.removeItem('confirm_code_time')
                localStorage.removeItem('confirm_email')
                localStorage.removeItem('confirm_userid')
                clearInterval(this.liveTimerId)
            }
        }
    },
    data() {
        return {
            inputs: {
                email: '',
                password: '',
                password_confirm: '',
                confirm_code: ''
            },
            lang: Lang,
            messages: [],
            message_classToggle: 'form_messages--fadeOut',
            message_toggle: 'form_messages--fadeOut',
            is_loading: false,
            liveTimeConfrimCode: 0,
            liveTimerId: null,
            action_form: this.action,
            is_resend_code: false,
            confirm_time: this.getCurrentTimer(60),
            seconds_text: [Lang('1 second'), Lang('2 second'), Lang('5 second')],
            cfrm_time_text: Lang('5 second'),
        }
    },
    methods: {
        liveTimeConfrimCodeUpdate() {
            const proxy = new Proxy(this, {})

            this.liveTimerId = setInterval(() => {
                proxy.liveTimeConfrimCode = parseInt(Date.now() / 1000)
            }, 500)
        },
        // Получить код кодтверждения повторно
        async reGetCodeConfirm() {
            const response = await this.getCodeConfirm()

            // Если все успешно
            if (response.status === 200) {
                this.is_resend_code = false
                this.confirm_time = 60
                this.timerUpdate()
                localStorage.setItem('confirm_code_time', parseInt(Date.now() / 1000))
            } else {
                console.log(response)
            }
        },
        // Обработка ответа запроса POST
        responseHandler(resp, action) {
            if (resp.status === 'warning') {
                const objMessage = {}

                objMessage[resp.status + '_' + resp.code] = Lang(resp.message)

                this.messagesPrint('response', objMessage)
                        
            } else if (resp.status === 'success') {
                if (action === 'show-pass') {
                    this.$router.push({ path: '/forgotpswd/show-final' })
                    this.action_form = 'show-final'
                    localStorage.removeItem('confirm_code_time')
                    localStorage.removeItem('confirm_userid')
                } else if (action === 'show-code') {
                    this.$router.push({ path: '/forgotpswd/show-pass' })
                    this.action_form = 'show-pass'
                    localStorage.removeItem('confirm_code_time')
                    localStorage.setItem('confirm_userid', resp.data.userid)
                } else {
                    this.$router.push({ path: '/forgotpswd/show-code' })
                    this.action_form = 'show-code'
                    this.is_resend_code = false
                    this.timerUpdate()
                    localStorage.setItem('confirm_code_time', parseInt(Date.now() / 1000))
                    localStorage.setItem('confirm_userid', resp.data.userid)
                }
            }
        },
        // Отправка формы
        async formSubmit(event) {
            const inputs = {} // Объявляем объект с инпутами

            // Пробегаемся по форме
            for (let i = 0; i < event.target.length; i++) {
                const element = event.target[i]
                const tagName = element.tagName.toLowerCase()
                
                // Проверяем является элемент инпутом или нет
                if (tagName === 'input') {
                    const keyName = event.target[i].name

                    inputs[keyName] = element // Добавляем в объект inputs
                    this.inputs[keyName] = event.target[i].value // Добавляем в глобальный объект инпут
                }
            }

            // Запускаем сообщения ошибок
            this.messagesPrint('inputs', inputs)

            // Если ошибок нет, то отправляем запрос
            if (!this.messages.length) {
                let urlAPI = ''
                let bodyData = ''
                let response

                if (this.action_form === 'show-pass') {
                    // Формируем URL для POST запроса
                    urlAPI = process.env.VUE_APP_REQUEST_DOMAIN + ReqUrls.users.update
                    bodyData = {
                        userid: parseInt(localStorage.getItem('confirm_userid')),
                        password: this.inputs.password,
                        password_confirm: this.inputs.password_confirm
                    }
                } else if (this.action_form === 'show-code') {
                    // Формируем URL для POST запроса
                    urlAPI = process.env.VUE_APP_REQUEST_DOMAIN + ReqUrls.accounts.confirm_code
                    bodyData = {
                        userid: parseInt(localStorage.getItem('confirm_userid')),
                        code: this.inputs.confirm_code
                    }
                } else {
                    localStorage.setItem('confirm_email', JSON.stringify({
                        email: this.inputs.email,
                        time: parseInt(Date.now() / 1000)
                    }))
                }
                
                this.is_loading = true // Включаем индикатор отправки запроса на сервер
                
                // Выполняем axios запрос
                if (this.action_form === 'show-pass' || this.action_form === 'show-code') response = await axios.post(urlAPI, bodyData, {withCredentials: true})
                else response = await this.getCodeConfirm()

                this.is_loading = false // Отключаем индикатор отправки запроса на сервер

                // Если все успешно
                if (response.status === 200) {
                    this.responseHandler(response.data, this.action_form) // Запускаем обработчик полученных данных
                } else {
                    console.log(response)
                }
            }
        }
    },
    created() {
        if (this.getCurrentTimer(60) < 60) this.timerUpdate()
        if (this.getCurrentTimer(60) === 60) {
            this.is_resend_code = true
            localStorage.removeItem('confirm_code_time')
        }
    },
    mounted() {
        if(this.action_form) this.liveTimeConfrimCodeUpdate()
    }
}
</script>

<style scoped>
.form-title,
.form-h1 {
    margin-top: 0.5rem;
    text-align: center;
}
.form-title {
    font-size: 18pt;
    margin-bottom: 0.5rem;
}
.form-h1 {
    font-size: 16pt;
    margin-bottom: 1.5rem;
    font-family: 'Roboto-Light'
}

.form__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
}

.btn-sigin {
    font-size: 12pt;
    font-weight: bold;
    color: #1b55b1;
    transition: color 0.3s;
}
.btn-sigin:hover {
    color: #205dc0;
    cursor: pointer;
    transition: color 0.3s;
}
</style>