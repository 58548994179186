import AuthPage from '../pages/auth/AuthPage'
import ForgotPswdPage from '../pages/auth/ForgotPswdPage'
import RegPage from '../pages/registration/RegPage'

export default [
    {
        path: '/',
        redirect: '/auth' 
    },
    {
        path: '/auth',
        name: 'auth',
        component: <AuthPage />
    },
    {
        path: '/registration/:action?',
        name: 'registration',
        component: <RegPage />
    },
    {
        path: '/forgotpswd/:action?',
        name: 'forgotpswd',
        component: <ForgotPswdPage />
    },
    // {
    //     path: '/personal',
    //     component: <ForgotPswdPage />
    // },
]