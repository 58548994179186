import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import './assets/css/app.css'

import components from './components/UI'
import routes from './router'
import App from './App'
import GetLocale from './locale'

GetLocale() // locale handle

const router = createRouter({
    history: createWebHistory(),
    routes,
})

const app = createApp(App)

app.use(router)
// app.use(createWebHistory())

components.forEach(component => {
    app.component(component.name, component)
})

app.mount('#app')