<template>
    <div :class="'logo ' + size + ' ' + modif">
        <a :href="home_redirect">
            <img alt="ButaGo" src="@/assets/logo-text.svg">
        </a>
    </div>
</template>

<script>
export default {
    name: 'LogoApp',
    props: {
        size: String,
        modif: String
    },
    data() {
        return {
            home_redirect: process.env.VUE_APP_DEFAULT_REDIRECT
        }
    }
}
</script>

<style scoped>
.logo {
    display: block;
    width: 300px;
}
.logo img {
    width: 100%;
}
.logo--medium {
    width: 200px;
}
.logo--small {
    width: 100px;
}
.logo--center {
    margin: 0 auto;
}
</style>
