<template>
    <div class="content content--vertical-center">
        <div class="wrap wrap--flex-column element-center95">
            <div class="box-center">
                <LogoApp size="logo--small" modif="logo--center" />
                <FormForgotPswd :action="this.action" />
            </div>
        </div>
    </div>
</template>

<script>
import FormForgotPswd from '@/components/FormForgotPswd'
import { Lang } from '@/lang'

export default {
    components: {
        FormForgotPswd   
    },
    name: 'ForgotPswdpage',
    created() {
        document.title = Lang('Access - ButaGo Account')

        const qparam = this.$route.params.action

        if (qparam) this.action = qparam
    },
    data() {
        return {
            action: ''
        }
    }
}
</script>

<style scoped>
.box-center {
    width: 25%;
    margin: 0 auto;
    border: 1px solid #ddd;
    padding: 40px 30px 60px 30px;
    border-radius: 4px;
}
@media screen and (max-width: 1024px) {
    .box-center {
        width: 40%;
    }
}
@media screen and (max-width: 700px) {
    .box-center {
        width: 50%;
    }
}
@media screen and (max-width: 500px) {
    .box-center {
        width: 80%;
    }
}
</style>