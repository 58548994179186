<template>
    <div class="form-box">
        <div class="form-h1">{{ lang('Registration in ButaGo') }}</div>
        <form class="form" @submit.prevent="formSubmit($event)">
            <div v-if="action_form === 'activation' || action_form === 'final'">

                <div v-if="action_form === 'final'">
                    <div class="form__success">
                        {{ lang('Account successfully verified') }}
                    </div>
                </div>

                <div v-if="action_form === 'activation'">
                    <div class="form__input" @click="formInputFocus($event)">
                        <input type="text" autocomplete="off" name="confirm_code" @focus="formInputFocus($event, true)" @blur="formInputBlur($event)" @input="formInputUseDigital($event)" />
                        <span>{{ lang('Confirmation code') }}</span>
                    </div>

                    <div v-if="!is_resend_code" class="form__timer" v-html="lang('You can get a new code in N seconds', {timerSecond: confirm_time, wordSecond: cfrm_time_text})"></div>
                    <button v-else type="button" @click="reGetCodeConfirm()" class="form__timer-btn">{{ lang('Send code') }}</button>
                </div>
            </div>

            <div v-else>
                <div class="form__inline">
                    <div class="form__input" @click="formInputFocus($event)" @keyup="indikatorInputs($event, 'name')">
                        <input type="text" autocomplete="off" name="name" @focus="formInputFocus($event, true)" @blur="formInputBlur($event)" />
                        <span>{{ lang('Name') }}</span>
                        <div class="input-indicator hint hint--right" :data-hint="level_messages.name">
                            <div :class="'indicator indicator--level-' + name_level"></div>
                        </div>
                    </div>
                    <div class="form__input" @click="formInputFocus($event)" @keyup="indikatorInputs($event, 'surname')">
                        <input type="text" autocomplete="off" name="surname" @focus="formInputFocus($event, true)" @blur="formInputBlur($event)" />
                        <span>{{ lang('Surname') }}</span>
                        <div class="input-indicator hint hint--right" :data-hint="level_messages.surname">
                            <div :class="'indicator indicator--level-' + surname_level"></div>
                        </div>
                    </div>
                </div>
                <div class="form__input" @click="formInputFocus($event)" @keyup="indikatorEmail($event)">
                    <input type="text" autocomplete="off" name="email" @focus="formInputFocus($event, true)" @blur="formInputBlur($event)" />
                    <span>{{ lang('E-mail') }}</span>
                    <div class="input-indicator hint hint--right" :data-hint="level_messages.email">
                        <div :class="'indicator indicator--level-' + email_level"></div>
                    </div>
                </div>
                <div class="form__input" @click="formInputFocus($event)" @keyup="indikatorPassword($event)">
                    <input type="password" autocomplete="off" name="password" @focus="formInputFocus($event, true)" @blur="formInputBlur($event)" />
                    <span>{{ lang('Password') }}</span>
                    <div class="input-indicator hint hint--right" :data-hint="level_messages.password">
                        <div :class="'indicator indicator--level-' + pwd_level"></div>
                    </div>
                </div>
                <div class="form__input" @click="formInputFocus($event)" @keyup="confirmPassword($event)">
                    <input type="password" autocomplete="off" name="confirm_password" @focus="formInputFocus($event, true)" @blur="formInputBlur($event)" />
                    <span>{{ lang('Confirm password') }}</span>
                    <div class="input-indicator hint hint--right" :data-hint="level_messages.confirm_password">
                        <div :class="'indicator indicator--level-' + pwd_confirm"></div>
                    </div>
                </div>
                <div class="form__info" v-html="privacy_terms_info"></div>
            </div>

            <div :class="'form_messages ' + message_toggle">
                <ul>
                    <li v-for="item in messages" :key="Object.keys(item)[0]">{{ Object.values(item)[0] }}</li>
                </ul>
            </div>

            <div :class="(action_form === 'activation') ? 'form__buttons form__buttons--flex-center' : 'form__buttons'" v-if="action_form != 'final'">
                <router-link to="/auth" custom v-slot="{ navigate, href }" v-if="action_form != 'activation'">
                    <a :href="href" @click="navigate" class="btn-create">{{ lang('Sign in') }}</a>
                </router-link>
                <ButtonForms :is_show="is_loading">
                    {{ (action_form === 'activation') ? lang('Verify account') : lang('Create an account') }}
                </ButtonForms>
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios'
import cookie from 'cookiejs'

import { ReqUrls } from '@/requstes'
import { buildParamsUri } from '@/http/functions'
import { Lang } from '@/lang'

export default {
    name: 'FormAuth',
    inject: [
        'timerUpdate',
        'declension_words',
        'messagesPrint',
        'getCodeConfirm',
        'formInputUseDigital',
        'getCurrentTimer'
    ],
    watch: {
        liveTimeConfrimCode(val, oldVal) {
            const uData = JSON.parse(localStorage.getItem('confirm_email')) || {email: '', time: parseInt(Date.now() / 1000) - 300}
            const diffTime = parseInt(val) - parseInt(uData.time)

            if (diffTime >= 300) {
                this.confirm_time = 60
                this.action_form = ''
                this.inputs.confirm_code = ''
                this.messages = []
                this.$router.push({ path: '/auth' })

                localStorage.removeItem('confirm_code_time')
                localStorage.removeItem('confirm_email')
                localStorage.removeItem('confirm_userid')
                clearInterval(this.liveTimerId)
            }
        }
    },
    data() {
        return {
            inputs: {
                email: '',
                password: '',
                confirm_password: '',
                name: '',
                surname: '',
                confirm_code: ''
            },
            privacy_terms_info: Lang('Links privacy policy and terms of use', {
                create_account: Lang('Create an account'),
                link_privacy_policy: `<a href="${process.env.VUE_APP_DEFAULT_REDIRECT}info/privacy" target="_blank" class="form-info__link">${Lang('Privacy policy')}</a>`,
                link_terms: `<a href="${process.env.VUE_APP_DEFAULT_REDIRECT}info/terms" target="_blank" class="form-info__link">${Lang('Terms')}</a>`,
            }),
            action_form: '',
            lang: Lang,
            messages: [],
            message_classToggle: 'form_messages--fadeOut',
            message_toggle: 'form_messages--fadeOut',
            pwd_level: 0,
            pwd_confirm: 0,
            name_level: 0,
            surname_level: 0,
            email_level: 0,
            level_messages: {
                name: Lang('Fill in the Name field'),
                surname: Lang('Fill in the Surname field'),
                email: Lang('Fill in the E-mail field'),
                password: Lang('Fill in the Password field'),
                confirm_password: Lang('Fill in the Confirm password field')
            },
            is_loading: false,
            // root_domain: process.env.VUE_APP_DEFAULT_REDIRECT,
            liveTimeConfrimCode: 0,
            liveTimerId: null,
            is_resend_code: false,
            confirm_time: this.getCurrentTimer(60),
            seconds_text: [Lang('1 second'), Lang('2 second'), Lang('5 second')],
            cfrm_time_text: Lang('5 second'),
        }
    },
    methods: {
        liveTimeConfrimCodeUpdate() {
            const proxy = new Proxy(this, {})

            this.liveTimerId = setInterval(() => {
                proxy.liveTimeConfrimCode = parseInt(Date.now() / 1000)
            }, 500)
        },
        // Обработка ответа запроса POST
        async responseHandler(resp) {
            if (resp.status === 'warning') {
                switch (resp.code) {
                    case 0: {
                        const objMessage = {}

                        objMessage[resp.status + '_' + resp.code] = Lang(resp.message)

                        this.messagesPrint('response', objMessage)
                        
                        break;
                    }
                }
            } else if (resp.status === 'success') {
                if (this.action_form === 'activation') {
                    this.$router.push({ path: '/registration/final' })
                    this.action_form = 'final'
                    localStorage.removeItem('confirm_code_time')
                    localStorage.removeItem('confirm_userid')
                    
                    const urlAPI = process.env.VUE_APP_REQUEST_DOMAIN + ReqUrls.accounts.auth // Формируем URL для POST запроса
                    const bodyData = {
                        email: this.inputs.email,
                        password: this.inputs.password
                    }

                    const response = await axios.post(urlAPI, bodyData, {withCredentials: true})
                    const resp2 = response.data

                    if (response.status === 200) {
                        if (resp2.status === 'success') {
                            if (cookie.get('butago.com:access_token').length) {
                                const params = buildParamsUri()

                                if (typeof params === 'object' && params.redirect) {
                                    window.location.href = params.redirect
                                } else {
                                    window.location.href = process.env.VUE_APP_DEFAULT_REDIRECT
                                }
                            }
                        }
                    }
                } else {
                    this.$router.push({ path: '/registration/activation' })
                    this.action_form = 'activation'
                    this.is_resend_code = false
                    this.timerUpdate()
                    localStorage.setItem('confirm_code_time', parseInt(Date.now() / 1000))
                    localStorage.setItem('confirm_userid', resp.data.userid)
                }
            }
        },
        // Отправка формы
        async formSubmit(event) {
            const inputs = {} // Объявляем объект с инпутами

            // Пробегаемся по форме
            for (let i = 0; i < event.target.length; i++) {
                const element = event.target[i]
                const tagName = element.tagName.toLowerCase()
                
                 // Проверяем является элемент инпутом или нет
                if (tagName === 'input') {
                    const keyName = event.target[i].name

                    inputs[keyName] = element // Добавляем в объект inputs
                    this.inputs[keyName] = event.target[i].value // Добавляем в глобальный объект инпут
                }
            }

            // Запускаем сообщения ошибок
            this.messagesPrint('inputs', inputs)

            // Если ошибок нет, то отправляем запрос
            if (!this.messages.length) {
                let urlAPI = ''
                let bodyData = ''

                if (this.action_form != 'activation') {
                    urlAPI = process.env.VUE_APP_REQUEST_DOMAIN + ReqUrls.accounts.registration // Формируем URL для POST запроса
                    bodyData = {
                        email: this.inputs.email,
                        password: this.inputs.password,
                        confirm_password: this.inputs.confirm_password,
                        name: this.inputs.name,
                        surname: this.inputs.surname
                    }
                } else {
                    // Формируем URL для POST запроса
                    urlAPI = process.env.VUE_APP_REQUEST_DOMAIN + ReqUrls.accounts.confirm_code
                    bodyData = {
                        userid: parseInt(localStorage.getItem('confirm_userid')),
                        code: this.inputs.confirm_code,
                        activation: true
                    }
                }

                this.is_loading = true // Включаем индикатор отправки запроса на сервер

                // Выполняем axios запрос
                const response = await axios.post(urlAPI, bodyData)

                this.is_loading = false // Отключаем индикатор отправки запроса на сервер

                // Если все успешно
                if (response.status === 201 || response.status === 200) {
                    this.responseHandler(response.data) // Запускаем обработчик полученных данных
                } else {
                    console.log(response)
                }
            }
        },
        confirmPassword(event) {
            const strValue = event.target.value
            const indicatorElement = event.target.nextElementSibling.nextElementSibling

            if (strValue.length) {
                const strValue2 = event.target.parentNode.previousSibling.childNodes[0].value

                if (strValue === strValue2) {
                    if (indicatorElement.classList.contains('hint')) {
                        indicatorElement.classList.remove('hint')
                        indicatorElement.classList.remove('hint--right')
                    }
                    
                    this.pwd_confirm = 3
                    this.level_messages.confirm_password = Lang('Passwords match')
                } else {
                    if (!indicatorElement.classList.contains('hint')) {
                        indicatorElement.classList.add('hint')
                        indicatorElement.classList.add('hint--right')
                    }
                    
                    this.pwd_confirm = 1
                    this.level_messages.confirm_password = Lang('Passwords don\'t match')
                }
            } else {
                if (!indicatorElement.classList.contains('hint')) {
                    indicatorElement.classList.add('hint')
                    indicatorElement.classList.add('hint--right')
                }

                this.pwd_confirm = 0
                this.level_messages.confirm_password = Lang('Repeat password')
            }
        },
        indikatorPassword(event) {
            const strValue = event.target.value
            const indicatorElement = event.target.nextElementSibling.nextElementSibling
            let level = 0

            if (strValue.length) {
                const strValue2 = event.target.parentNode.nextSibling.childNodes[0].value
                const indicatorElement2 = event.target.parentNode.nextSibling.childNodes[0].nextElementSibling.nextElementSibling

                const char_low = 'abcdefghijklmnopqrstuvwxyzабвгдеёжзийклмнопрстуфхцчшщъыьэюя'
                const char_up = 'ABCDEFGHIJKLMNOPRQSTUVWXYZАБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯ'
                const digital = '0123456789'
                const special = '!@#$%^&?*()_:;.,-=+/|\\[]{}'

                const is_bools = {
                    c_low: false,
                    c_up: false,
                    dig: false,
                    spec: false
                }

                for (let i = 0; i < strValue.length; i++) {
                    if (!is_bools['c_low'] && char_low.indexOf(strValue[i]) != -1) is_bools['c_low'] = true
                    else if (!is_bools['c_up'] && char_up.indexOf(strValue[i]) != -1) is_bools['c_up'] = true
                    else if (!is_bools['dig'] && digital.indexOf(strValue[i]) != -1) is_bools['dig'] = true
                    else if (!is_bools['spec'] && special.indexOf(strValue[i]) != -1) is_bools['spec'] = true
                }

                let rating = 0

                for (let [key, value] of Object.entries(is_bools)) {
                    if (value) rating++
                }

                if (strValue.length < 6 && rating < 3) level = 1
                else if (strValue.length < 6 && rating >= 3) level = 1
                else if (strValue.length >= 8 && rating < 3) level = 2
                else if (strValue.length >= 8 && rating == 3) level = 3
                else if (strValue.length >= 6 && rating == 1) level = 2
                else if (strValue.length >= 6 && rating > 1 && rating < 4) level = 2
                else if (strValue.length >= 6 && rating >= 4) level = 3

                switch (level) {
                    case 0:
                        this.level_messages.password = Lang('Enter password')

                        if (!indicatorElement.classList.contains('hint')) {
                            indicatorElement.classList.add('hint')
                            indicatorElement.classList.add('hint--right')
                        }

                        break;

                    case 1:
                        this.level_messages.password = Lang('The password is too simple. Use at least 6 characters') + '.'

                        if (!indicatorElement.classList.contains('hint')) {
                            indicatorElement.classList.add('hint')
                            indicatorElement.classList.add('hint--right')
                        }

                        break;
                
                    case 2:
                        this.level_messages.password = Lang('The password is easy to guess. Use lower and upper case letters, numbers and special characters') + '.'

                        if (!indicatorElement.classList.contains('hint')) {
                            indicatorElement.classList.add('hint')
                            indicatorElement.classList.add('hint--right')
                        }

                        break;
                    case 3:
                        this.level_messages.password = Lang('Password strong')

                        if (indicatorElement.classList.contains('hint')) {
                            indicatorElement.classList.remove('hint')
                            indicatorElement.classList.remove('hint--right')
                        }

                        break;
                }

                // Проверяем вводили ли подтверждение пароля
                if (strValue2.length) {
                    // Если пароли совпадают
                    if (strValue === strValue2) {
                        this.pwd_confirm = 3
                        this.level_messages.confirm_password = Lang('Passwords match')

                        if (indicatorElement2.classList.contains('hint')) {
                            indicatorElement2.classList.remove('hint')
                            indicatorElement2.classList.remove('hint--right')
                        }
                    } else {
                        this.pwd_confirm = 1
                        this.level_messages.confirm_password = Lang('Passwords don\'t match')

                        if (!indicatorElement2.classList.contains('hint')) {
                            indicatorElement2.classList.add('hint')
                            indicatorElement2.classList.add('hint--right')
                        }
                    }
                } else {
                    this.pwd_confirm = 0
                    this.level_messages.confirm_password = Lang('Repeat password')

                    if (!indicatorElement2.classList.contains('hint')) {
                        indicatorElement2.classList.add('hint')
                        indicatorElement2.classList.add('hint--right')
                    }
                }
            } else {
                this.level_messages.password = Lang('Fill in the Password field')

                if (!indicatorElement.classList.contains('hint')) {
                    indicatorElement.classList.add('hint')
                    indicatorElement.classList.add('hint--right')
                }
            }
            
            this.pwd_level = level
        },
        formInputFocus(event, is_input) {
            if (is_input === undefined || is_input === null || is_input === '') is_input = false 

            const elem = event.target

            if (is_input === false) {
                const tag_name = event.target.tagName.toLowerCase()
                let input = null

                if (tag_name === 'span' || tag_name === 'div') {
                    if (tag_name === 'span') {
                        input = elem.previousSibling
                    } else {
                        input = elem.childNodes[0]
                    }
                    
                    input.parentNode.classList.add('form__input--focused')
                    if (input.nextElementSibling.nextElementSibling) input.nextElementSibling.nextElementSibling.classList.add('input-indicator--fadeIn');

                    input.focus()
                }
            } else {
                elem.parentNode.classList.add('form__input--focused')
                if (elem.nextElementSibling.nextElementSibling) elem.nextElementSibling.nextElementSibling.classList.add('input-indicator--fadeIn');
            }
        },
        formInputBlur(event) {
            const elem = event.target
            const items = elem.parentNode.parentNode.querySelectorAll('.form__input')

            for (let i = 0; i < items.length; i++) {
                const strValue = items[i].childNodes[0].value

                if (!strValue.length) {
                    items[i].classList.remove('form__input--focused')
                }

                // Если инпуты  индикаторами имеются
                if (items[i].childNodes[2]) {
                    if (items[i].childNodes[2].classList.length) {
                        const childIndicator = items[i].childNodes[2].childNodes[0]
                        const classListIndicator = childIndicator.classList

                        for (let key of classListIndicator) {
                            if (key === 'indicator--level-0' && !strValue.length) items[i].childNodes[2].classList.remove('input-indicator--fadeIn')
                        }
                    }
                }
            }
        },
        indikatorInputs(event, type) {
            const strValue = event.target.value
            const indicatorElement = event.target.nextElementSibling.nextElementSibling

            if (strValue.length) {
                if (indicatorElement.classList.contains('hint')) {
                    indicatorElement.classList.remove('hint')
                    indicatorElement.classList.remove('hint--right')
                }
            } else {
                if (!indicatorElement.classList.contains('hint')) {
                    indicatorElement.classList.add('hint')
                    indicatorElement.classList.add('hint--right')
                }
            }

            switch (type) {
                case 'name': {
                    this.name_level = (strValue.length) ? 3 : 0

                    break;
                }
                case 'surname': {
                    this.surname_level = (strValue.length) ? 3 : 0

                    break;
                }
            }
        },
        indikatorEmail(event) {
            const strValue = event.target.value
            const indicatorElement = event.target.nextElementSibling.nextElementSibling

            if (!strValue.length) {
                this.email_level = 0
                this.level_messages.email = Lang('Enter E-mail address')

                if (!indicatorElement.classList.contains('hint')) {
                    indicatorElement.classList.add('hint')
                    indicatorElement.classList.add('hint--right')
                }

                return false;
            }

            let pattern = /^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{2,10}\.)?[a-z]{2,10}$/i // eslint-disable-line

            if (!pattern.test(strValue)) {
                if (!indicatorElement.classList.contains('hint')) {
                    indicatorElement.classList.add('hint')
                    indicatorElement.classList.add('hint--right')
                }

                this.email_level = 1
                this.level_messages.email = Lang('E-mail entered incorrectly') + ' -> example@mail.com'
            } else {
                if (indicatorElement.classList.contains('hint')) {
                    indicatorElement.classList.remove('hint')
                    indicatorElement.classList.remove('hint--right')
                }

                this.email_level = 3
                this.level_messages.email = Lang('E-mail entered correctly')
            }
        },
    },
    created() {
        const qparam = this.$route.params.action

        if (qparam) this.action_form = qparam

        if (this.getCurrentTimer(60) < 60) this.timerUpdate()
        if (this.getCurrentTimer(60) === 60) {
            this.is_resend_code = true
            localStorage.removeItem('confirm_code_time')
        }
    },
    mounted() {
        if(this.action_form) this.liveTimeConfrimCodeUpdate()
    }
}
</script>

<style scoped>
.form-title,
.form-h1 {
    margin-top: 0.5rem;
    text-align: center;
}
.form-title {
    font-size: 18pt;
    margin-bottom: 0.5rem;
}
.form-h1 {
    font-size: 16pt;
    margin-bottom: 1.5rem;
    font-family: 'Roboto-Light'
}

.form__input > .input-indicator {
    right: 0px;
    top: 0px;
    transform: translateX(-18px) translateY(20px) scale(0);
    position: absolute;
    opacity: 0;
    transition: transform 0.5s, opacity 0.5s;
}
.input-indicator > .indicator {
    width: 25px;
    height: 25px;
    border-radius: 80%;
}
.indicator--level-0 {
    background-color: #ddd;
    transition: background 0.5s;
}
.indicator--level-1 {
    background-color: #c04545;
    transition: background 0.5s;
}
.indicator--level-2 {
    background-color: #ff9900;
    transition: background 0.5s;
}
.indicator--level-3 {
    background-color: #46bb4c;
    transition: background 0.5s;
}
.input-indicator--fadeIn {
    transform: translateX(-18px) translateY(20px) scale(1) !important;
    opacity: 1 !important;
    transition: transform 0.5s, opacity 0.5s;
}

.form__inline {
    display: flex;
    justify-content: space-between;
}
.form__inline .form__input {
    width: 48%;
}

.form__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
}
.btn-create {
    font-size: 12pt;
    font-weight: bold;
    color: #1b55b1;
    transition: color 0.3s;
}
.btn-create:hover {
    color: #205dc0;
    cursor: pointer;
    transition: color 0.3s;
}
@media screen and (max-width: 500px) {
    .form__inline {
        flex-direction: column;
    }
    .form__inline .form__input {
        width: auto;
    }
}
</style>