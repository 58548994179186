import Lang_AZ from './az'
import Lang_RU from './ru'
import Lang_EN from './en'
import Lang_TR from './tr'

const langCode = localStorage.getItem('locale')
const langs = {
    AZ: Lang_AZ,
    EN: Lang_EN,
    RU: Lang_RU,
    TR: Lang_TR,
}
const current_lang = langs[langCode]


export const Lang = (str, inc) => {
    str = current_lang[str]

    if (inc && typeof inc === 'object') {
        for (let key in inc) {
            // console.log(key, inc[key])
            str = str.replaceAll(':' + key, inc[key])
        }
    }
    
    return str
}