<template>
    <div class="content content--vertical-center">
        <div class="wrap wrap--flex-column element-center95">
            <div class="box-center">
                <LogoApp size="logo--small" modif="logo--center" />
                <FormAuth />
            </div>
        </div>
    </div>
</template>

<script>
import FormAuth from '@/components/FormAuth'
import { Lang } from '@/lang'

export default {
    components: {
        FormAuth   
    },
    name: 'Authpage',
    created() {
        document.title = Lang('Auth - ButaGo Account')
    }
}
</script>

<style scoped>
.box-center {
    width: 25%;
    margin: 0 auto;
    border: 1px solid #ddd;
    padding: 40px 30px 60px 30px;
    border-radius: 4px;
}
@media screen and (max-width: 1024px) {
    .box-center {
        width: 40%;
    }
}
@media screen and (max-width: 700px) {
    .box-center {
        width: 50%;
    }
}
@media screen and (max-width: 500px) {
    .box-center {
        width: 80%;
    }
}
</style>