import axios from 'axios'

import { ReqUrls } from './requstes'

export default async () => {
    const urlAPI = process.env.VUE_APP_REQUEST_DOMAIN + ReqUrls.accounts.locale // Формируем URL для POST запроса

    // Выполняем axios запрос
    const response = await axios.get(urlAPI, {withCredentials: true})

    // Если все успешно
    if (response.status === 200) {
        localStorage.setItem('locale', response.data.localeCode)
    } else {
        localStorage.setItem('locale', 'AZ')
    }
}