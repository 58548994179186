export const ReqUrls = {
    accounts: {
        locale: '/v1/accounts/locale',
        auth: '/v1/accounts/auth',
        auth_verify: '/v1/accounts/auth_verify',
        registration: '/v1/accounts/registration',
        refresh: '/v1/accounts/refresh',
        confirm_code: '/v1/accounts/confirm_code',
        forgot_password: '/v1/accounts/forgot_password'
    },
    users: {
        update: '/v1/users/update',
    }
}