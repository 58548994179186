import axios from 'axios'
import cookie from 'cookiejs'

import { ReqUrls } from '@/requstes'

const $api = axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_REQUEST_DOMAIN
})

$api.interceptors.request.use((config) => {
    const accessToken = cookie.get('butago.com:access_token')

    config.headers.Authorization = `Bearer ${(accessToken.length) ? accessToken : ''}`
    config.headers.crossDomain = true

    return config
})

$api.interceptors.response.use((config) => {
    return config;
}, async (error) => {
    if (error.response.status === 401) {
        if (cookie.get('butago.com:access_token').length) {
            let response = await $api.get(ReqUrls.accounts.refresh)

            if (response) {
                if (response.status === 200) {
                    if (!response.data.access_token) {
                        if (window.location.pathname != '/auth') window.location.href = '/auth'
                    }
                }
            }
        }
    }
})

export default $api