<template>
    <div class="content content--vertical-center">
        <div class="wrap wrap--flex-column element-center95">
            <div class="box-center">
                <LogoApp size="logo--small" modif="logo--center" />
                <FormReg />
            </div>
        </div>
    </div>
</template>

<script>

import FormReg from '@/components/FormReg'
import { Lang } from '@/lang'

export default {
    components: {
        FormReg
    },
    name: 'Regpage',
    created() {
        document.title = Lang('Reg - ButaGo Account')
    }
}
</script>

<style scoped>
.box-center {
    width: 35%;
    margin: 0 auto;
    border: 1px solid #ddd;
    padding: 40px 30px 60px 30px;
    border-radius: 4px;
}

@media screen and (max-width: 1024px) {
    .box-center {
        width: 65%;
    }
}
@media screen and (max-width: 700px) {
    .box-center {
        width: 75%;
    }
}
@media screen and (max-width: 500px) {
    .box-center {
        width: 80%;
    }
}
</style>