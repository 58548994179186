export default {
    // Auth|Registration messages
    'E-mail or password entered incorrectly': 'E-posta veya şifre yanlış girildi',
    'Successful authentication': 'Başarılı kimlik doğrulama',
    'Successful authorization': 'Başarılı yetkilendirme',
    'Enter password': 'Şifreyi girin',
    'Passwords don\'t match': 'Şifreler aynı değil',
    'Passwords match': 'Şifreler eşleşti',
    'Repeat password': 'Şifreyi tekrarlayın',
    'The user has been successfully created': 'Kullanıcı başarıyla oluşturuldu',
    'Sorry, the user was not created': 'Üzgünüz, kullanıcı oluşturulmadı',
    'A user with this email address already exists': 'Bu e-posta adresine sahip bir kullanıcı zaten var',
    'Access and Refresh tokens have been successfully received': 'Access ve Refresh belirteçleri başarıyla alındı',
    'An error occurred while receiving Access and Refresh tokens': 'Access ve Refresh belirteçleri alırken bir hata oluştu',
    'Fill in the Name field': 'Ad alanını doldurun',
    'Fill in the Surname field': 'Ad alanını doldurun',
    'Fill in the E-mail field': 'E-posta alanını doldurun',
    'Fill in the Password field': 'Şifre alanını doldurun',
    'Fill in the Confirm password field': 'Şifreyi onayla alanını doldurun',
    'Enter E-mail address': 'E-posta adresini girin',
    'E-mail entered correctly': 'E-posta doğru girildi',
    'E-mail entered incorrectly': 'E-posta yanlış girildi',
    'The password is too simple. Use at least 6 characters': 'Şifre çok basit. En az 6 karakter kullanın',
    'Password strong': 'Şifre güvenilirdir',
    'The password is easy to guess. Use lower and upper case letters, numbers and special characters': "Şifreyi tahmin etmek kolaydır.\r\nKüçük ve büyük harfler, sayılar ve özel karakterler kullanın: !@#$%^&?*()_:;.,-=+/|\\[]{}",

    // Other fields
    'Auth - ButaGo Account': 'Giriş — ButaGo Hesabı',
    'Reg - ButaGo Account': 'Kayıt — ButaGo Hesabı',
    'Access - ButaGo Account': 'Erişim kurtarma — ButaGo Hesabı',
    'Registration in ButaGo': 'ButaGo\'da Kayıt',
    'Sign in with ButaGo': 'ButaGo ile giriş',
    'Access recovery': 'Erişim kurtarma',
    'Name': 'Ad',
    'Surname': 'Soyadı',
    'E-mail': 'E-posta',
    'Password': 'Şifre',
    'Confirm password': 'Şifreyi onayla',
    'Sign in': 'Giriş',
    'Sign in account': 'Hesapta oturum aç',
    'Create an account': 'Hesap oluştur',
    'Verify account': 'Hesabı onayla',
    'Forgot your password': 'Şifreyi unuttunuz mu',
    'Back Search': 'Aramaya geri',
    'Account successfully verified': 'Hesap başarıyla onaylandı',
    'Confirmation code': 'Onay kodu',
    'Send code': 'Kodu gönder',
    'Send': 'Göndermek',
    'New password': 'Yeni şifre',
    'New password confirmation': 'Yeni şifreyi onayla',
    'Password has been successfully changed': 'Şifre başarıyla değiştirildi',
    'Check code': 'Kodu kontrol et',
    'Update password': 'Şifreyi yenile',
    'You can get a new code in N seconds': 'Yeni kodu <span>:timerSecond</span> :wordSecond içinde alabilirsiniz',
    '1 second': 'saniye',
    '2 second': 'saniye',
    '5 second': 'saniye',
    'Privacy policy': 'gizlilik politikamızı',
    'Terms': 'kullanım şartlarımızı',
    'Links privacy policy and terms of use': '<b>:create_account</b> düğmesine tıklayarak :link_privacy_policy ve :link_terms kabul etmiş olursunuz',
}