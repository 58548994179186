<template>
  <router-view></router-view>
</template>

<script>
import axios from 'axios'
import cookie from 'cookiejs'

import $api from '@/http/axios'
import { ReqUrls } from '@/requstes'
import { buildParamsUri } from '@/http/functions'
// import { Lang } from '@/lang'

export default {
	name: 'App',
	created() {
		this.checkAuth()
	},
	methods: {
		async checkAuth() {
            const params = buildParamsUri()
            const current_url = new URL(window.location.href)

            if (cookie.get('butago.com:access_token').length) {
                const response = await $api.get(ReqUrls.accounts.auth_verify)
                
                // Если все успешно
                if (response && response.status === 200) {
                    if (typeof params === 'object' && params.redirect) {
                        if (process.env.VUE_APP_IS_DEBUG == 'true') {
                            const access_token = cookie.get('butago.com:access_token')
                            const refresh_token = localStorage.getItem('refresh_token')
                            let url_redirect = params.redirect

                            if (url_redirect.indexOf('?') != -1) {
                                url_redirect += '&debug_access='+encodeURIComponent(access_token)+'&debug_refresh='+encodeURIComponent(refresh_token)
                            } else {
                                url_redirect += '?debug_access='+encodeURIComponent(access_token)+'&debug_refresh='+encodeURIComponent(refresh_token)
                            }

                            window.location.href = url_redirect
                        } else {
                            window.location.href = params.redirect
                        }
                    } else {
                        if (current_url.pathname === '/auth') window.location.href = '/personal'
                    }
                } else {
                    console.log('Unauthorized')

                    if (current_url.pathname != '/auth') window.location.href = '/auth'+current_url.search
                }
            } else {
                console.log('Unauthorized')
                // this.$router.push({ path: '/auth', query: { redirect: this.$router.currentRoute.value.query.redirect } })

                if (current_url.pathname != '/auth') window.location.href = '/auth'+current_url.search
            }
		},
	},
	provide: {
		// Ввод поля доступно только для цифр
        formInputUseDigital(event, size) {
            const elem = event.target
            let strFilter = elem.value

            if (!size) size = 6

            strFilter = strFilter.replaceAll(/[^\d]/gi, '')

            if (strFilter.length > size) strFilter = strFilter.substr(0, size)

            elem.value = strFilter
        },
		// Получение оставшегося времени
        getCurrentTimer(max) {
            const old_time = localStorage.getItem('confirm_code_time')
            const cur_time = parseInt(Date.now() / 1000)
            const diff = cur_time - old_time

            if (diff >= 60 ) return 60

            return max - diff
        },
		// Для поля при фокусе
		formInputFocus(event, is_input) {
			if (is_input === undefined || is_input === null || is_input === '') is_input = false

			const elem = event.target
			
			if (is_input === false) {
				const tag_name = event.target.tagName.toLowerCase()
				let input = null

				if (tag_name === 'span' || tag_name === 'div') {
					if (tag_name === 'span') {
						input = elem.previousSibling
					} else {
						input = elem.childNodes[0]
					}
					
					input.parentNode.classList.add('form__input--focused')
					input.focus()
				}
			} else {
				elem.parentNode.classList.add('form__input--focused')
			}
		},
		// Для поля при отключении фокуса
		formInputBlur(event) {
			const elem = event.target
			const items = elem.parentNode.parentNode.querySelectorAll('.form__input')

			for (let i = 0; i < items.length; i++) {
				if (!items[i].childNodes[0].value.length) {
					items[i].classList.remove('form__input--focused')
				}
			}
		},
		// Склонение слова от числа
		declension_words(n, words) {
            const a = n % 10
            const b = n % 100

            if (a === 0 || a >= 5 || (b >= 10 && b <= 20)) return words[2]
            else if (a >= 2 && a <= 4) return words[1]

            return words[0]
        },
		// Обновление таймера
        timerUpdate() {
            const proxy = new Proxy(this, {})
            const recursiveTimer = function() {
                if (proxy.confirm_time > 0) {
                    proxy.confirm_time--

                    proxy.cfrm_time_text = proxy.declension_words(proxy.confirm_time, proxy.seconds_text)

                    setTimeout(recursiveTimer, 999)
                } else {
					proxy.is_resend_code = true
				}
            }

            proxy.confirm_time--

            proxy.cfrm_time_text = this.declension_words(proxy.confirm_time, proxy.seconds_text)
            
            setTimeout(recursiveTimer, 1000)
        },
		// Вывод ошибок на экран
        messagesPrint(_switch, data) {
            let isMessageShow = false // Влючаетль показа ошибок
            this.messages = [] // Глобальный массив с ошибками, обнуляем

            switch (_switch) {
                case 'inputs': {
                    for (let key in data) {
                        if (!data[key].value.length) {
                            isMessageShow = true
                            
                            const keyName = data[key].name
                            const keyValue = data[key].nextElementSibling.innerText
                            const objMessage = {}
                            
                            objMessage[keyName] = 'Введите ' + keyValue

                            this.messages.push(objMessage)
                        }
                    }

                    break;
                }
                case 'response': {
                    isMessageShow = true

                    this.messages.push(data)

                    break;
                }
            }

            if (isMessageShow === true) {
                this.message_toggle = ''
            } else {
                this.message_toggle = this.message_classToggle
            }
        },
		// Получение кода подтверждения
		async getCodeConfirm() {
			const userData = JSON.parse(localStorage.getItem('confirm_email'))
			const urlAPI = process.env.VUE_APP_REQUEST_DOMAIN + ReqUrls.accounts.forgot_password // Формируем URL для POST запроса
			const bodyData = {
				email: this.inputs.email || userData.email,
				step: 1
			}
			
			// Выполняем axios запрос
			const response = await axios.post(urlAPI, bodyData, {withCredentials: true})

			return response
		}
	}
}
</script>
<style>
.form__input {
    display: flex;
    margin-bottom: 15px;
    position: relative;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: transparent;
    height: 65px;
}
.form__input > input {
    font-size: 12pt;
    display: block;
    width: 100%;
    padding: 0px 18px;
    background-color: transparent;
    outline: none;
    border: none;
    border-radius: 4px;
    margin-top: 15px;
    z-index: -10;
    opacity: 0;
}
.form__input > span {
    left: 0px;
    top: 0px;
    position: absolute;
    transform: translateX(18px) translateY(25px);
    font-size: 12pt;
    color: #868686;
    transition: font-size 0.5s, transform 0.5s;
}
.form__input--focused > span {
    transform: translateX(18px) translateY(10px);
    font-size: 10.5pt;
    transition: font-size 0.5s, transform 0.5s;
    color: #333 !important;
}
.form__input--focused {
    border-color: #333 !important;
}
.form__input--focused > input {
    z-index: 0;
    opacity: 1;
}

.form_messages {
    display: flex;
    position: relative;
    padding: 0.5rem 0.5rem;
    margin-top: 1rem;
    border: 1px solid transparent;
    border-radius: 4px;
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
    opacity: 1;
    z-index: 0;
    height: auto;
    transition: opacity 0.5s, z-index 0.5s, height 0.5s, margin-top 0.5s;
}
.form_messages ul {
    font-size: 10.5pt;
    margin-left: 20px;
}
.form_messages ul li {
    line-height: 22px;
}
.form_messages--fadeOut {
    margin-top: 0px;
    height: 0px;
    opacity: 0;
    z-index: -10;
    transition: opacity 0.5s, z-index 0.5s, height 0.5s, margin-top 0.5s;
}

.form__timer {
    font-size: 10pt;
    color: #999;
}
.form__timer span {
    font-weight: bold;
}
.form__timer-btn {
    display: inline-block;
    font-size: 11pt;
    outline: none;
    border: none;
    background: transparent;
}
.form__timer-btn:hover {
    cursor: pointer;
    text-decoration: underline dashed;
}

.form__buttons--flex-center {
    justify-content: center !important;
}

.form__success {
    background: rgba(9, 234, 47, 0.15);
    border: 1px solid #0add2d80;
    padding: 15px;
    text-align: center;
}

.form__info {
    margin-top: 1.5rem;
    font-size: 10pt;
    font-family: 'Roboto-Light'
}
.form-info__link {
    color: #205dc0 !important;
}
.form-info__link:hover {
    color: #205dc0 !important;
    text-decoration: underline !important;
}
</style>