<template>
    <button class="btn-signin" type="submit">
        <slot v-if="!is_show"></slot>
        <transition name="fade" v-else>
            <Spinner v-if="is_show" color="spinner-border--color-white" size="spinner-border--size-sm" />
        </transition>
    </button>
</template>

<script>
export default {
    name: 'ButtonForms',
    props: {
        is_show: Boolean
    }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.btn-signin {
    display: inline-block;
    background: #349234;
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    font-size: 12pt;
    transition: background 0.3s;
}
.btn-signin:hover {
    background: #4aa84a;
    cursor: pointer;
    transition: background 0.3s;
}
</style>
