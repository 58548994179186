<template>
    <div class="form-box">
        <div class="form-h1">{{ lang('Sign in with ButaGo') }}</div>
        <form class="form" @submit.prevent="formSubmit($event)">
            <div class="form__input" @click="formInputFocus($event)">
                <input type="text" name="email" @focus="formInputFocus($event, true)"  @blur="formInputBlur($event)" />
                <span>{{ lang('E-mail') }}</span>
            </div>
            <div class="form__input" @click="formInputFocus($event)">
                <input type="password" name="password" @focus="formInputFocus($event, true)"  @blur="formInputBlur($event)" />
                <span>{{ lang('Password') }}</span>
            </div>
            <router-link to="/forgotpswd" custom v-slot="{ navigate, href }">
                <a :href="href" @click="navigate" class="btn-forgotpswd">{{ lang('Forgot your password') }}?</a>
            </router-link>

            <div :class="'form_messages ' + message_toggle">
                <ul>
                    <li v-for="item in messages" :key="Object.keys(item)[0]">{{ Object.values(item)[0] }}</li>
                </ul>
            </div>

            <div class="form__buttons">
                <div class="form-buttons__link">
                    <router-link to="/registration" custom v-slot="{ navigate, href }">
                        <a :href="href" @click="navigate" class="btn-create">{{ lang('Create an account') }}</a>
                    </router-link>
                    <a :href="home_path" class="link">{{ lang('Back Search') }}</a>
                </div>
                
                <ButtonForms :is_show="is_loading">{{ lang('Sign in') }}</ButtonForms>
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios'
import cookie from 'cookiejs'

import { ReqUrls } from '@/requstes'
import { buildParamsUri } from '@/http/functions'
import { Lang } from '@/lang'

export default {
    name: 'FormAuth',
    inject: [
        'formInputFocus',
        'formInputBlur',
        'messagesPrint',
    ],
    data() {
        return {
            inputs: {
                email: '',
                password: ''
            },
            lang: Lang,
            messages: [],
            message_classToggle: 'form_messages--fadeOut',
            message_toggle: 'form_messages--fadeOut',
            is_loading: false,
            home_path: process.env.VUE_APP_DEFAULT_REDIRECT
        }
    },
    methods: {
        // Обработка ответа запроса POST
        responseHandler(resp) {
            if (resp.status === 'warning') {
                const objMessage = {}

                objMessage[resp.status + '_' + resp.code] = Lang(resp.message)

                this.messagesPrint('response', objMessage)
                        
            } else if (resp.status === 'success') {
                if (cookie.get('butago.com:access_token').length) {
                    const params = buildParamsUri()

                    if (typeof params === 'object' && params.redirect) {
                        if (process.env.VUE_APP_IS_DEBUG == 'true') {
                            let url_redirect = params.redirect

                            if (url_redirect.indexOf('?') != -1) {
                                url_redirect += '&debug_access='+encodeURIComponent(resp.data.access_token)+'&debug_refresh='+encodeURIComponent(resp.data.refresh_token)
                            } else {
                                url_redirect += '?debug_access='+encodeURIComponent(resp.data.access_token)+'&debug_refresh='+encodeURIComponent(resp.data.refresh_token)
                            }

                            localStorage.setItem('refresh_token', resp.data.refresh_token)
                            window.location.href = url_redirect
                        } else {
                            window.location.href = params.redirect
                        }
                    } else {
                        window.location.href = process.env.VUE_APP_DEFAULT_REDIRECT
                    }
                }
            }
        },
        // Отправка формы
        async formSubmit(event) {
            const inputs = {} // Объявляем объект с инпутами

            // Пробегаемся по форме
            for (let i = 0; i < event.target.length; i++) {
                const element = event.target[i]
                const tagName = element.tagName.toLowerCase()
                
                // Проверяем является элемент инпутом или нет
                if (tagName === 'input') {
                    const keyName = event.target[i].name

                    inputs[keyName] = element // Добавляем в объект inputs
                    this.inputs[keyName] = event.target[i].value // Добавляем в глобальный объект инпут
                }
            }

            // Запускаем сообщения ошибок
            this.messagesPrint('inputs', inputs)

            // Если ошибок нет, то отправляем запрос
            if (!this.messages.length) {
                const urlAPI = process.env.VUE_APP_REQUEST_DOMAIN + ReqUrls.accounts.auth // Формируем URL для POST запроса
                const bodyData = {
                    email: this.inputs.email,
                    password: this.inputs.password
                }

                this.is_loading = true // Включаем индикатор отправки запроса на сервер
                
                // Выполняем axios запрос
                const response = await axios.post(urlAPI, bodyData, {withCredentials: true})

                this.is_loading = false // Отключаем индикатор отправки запроса на сервер

                // Если все успешно
                if (response.status === 200) {
                    this.responseHandler(response.data) // Запускаем обработчик полученных данных
                } else {
                    console.log(response)
                }
            }
        }
    }
}
</script>

<style scoped>
.form-title,
.form-h1 {
    margin-top: 0.5rem;
    text-align: center;
}
.form-title {
    font-size: 18pt;
    margin-bottom: 0.5rem;
}
.form-h1 {
    font-size: 16pt;
    margin-bottom: 1.5rem;
    font-family: 'Roboto-Light'
}
.form__input {
    display: flex;
    margin-bottom: 15px;
    position: relative;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: transparent;
    height: 65px;
}

.form__input > input {
    font-size: 12pt;
    display: block;
    width: 100%;
    padding: 0px 18px;
    background-color: transparent;
    outline: none;
    border: none;
    border-radius: 4px;
    margin-top: 15px;
    z-index: -10;
    opacity: 0;
}

.form__input > span {
    left: 0px;
    top: 0px;
    position: absolute;
    transform: translateX(18px) translateY(25px);
    font-size: 12pt;
    color: #868686;
    transition: font-size 0.5s, transform 0.5s;
}
.form__input--focused > span {
    transform: translateX(18px) translateY(10px);
    font-size: 10.5pt;
    transition: font-size 0.5s, transform 0.5s;
    color: #333 !important;
}
.form__input--focused {
    border-color: #333 !important;
}
.form__input--focused > input {
    z-index: 0;
    opacity: 1;
}

.form__buttons {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 2rem;
}
.form-buttons__link {
    display: flex;
    flex-direction: column;
}
.form-buttons__link > .link {
    font-size: 10pt;
    color: #1b55b1;
    margin-top: 0.35rem;
}
.form-buttons__link > .link:hover {
    text-decoration: underline;
}
.btn-forgotpswd {
    font-size: 10pt;
    color: #999;
    transition: color 0.3s;
}
.btn-create {
    font-size: 12pt;
    font-weight: bold;
    color: #1b55b1;
    transition: color 0.3s;
}
.btn-create:hover,
.btn-forgotpswd:hover {
    color: #205dc0;
    cursor: pointer;
    transition: color 0.3s;
}
.form_messages {
    display: flex;
    position: relative;
    padding: 0.5rem 0.5rem;
    margin-top: 1rem;
    border: 1px solid transparent;
    border-radius: 4px;
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
    opacity: 1;
    z-index: 0;
    height: auto;
    transition: opacity 0.5s, z-index 0.5s, height 0.5s, margin-top 0.5s;
}
.form_messages ul {
    font-size: 10.5pt;
    margin-left: 20px;
}
.form_messages ul li {
    line-height: 22px;
}
.form_messages--fadeOut {
    margin-top: 0px;
    height: 0px;
    opacity: 0;
    z-index: -10;
    transition: opacity 0.5s, z-index 0.5s, height 0.5s, margin-top 0.5s;
}
</style>