<template>
    <div class="spinner">
        <div :class="'spinner-border ' + color + ' ' + size + ' ' + ((modif) ? modif : '')"></div>
    </div>
</template>

<script>
export default {
    name: 'Spinner',
    props: {
        size: String,
        color: String,
        modif: String
    }
}
</script>

<style scoped>
.spinner {
    margin-bottom: -2px;
    opacity: 1;
    z-index: 0;
    transition: opacity 0.3s, z-index  0.3s;
}
.spinner-border {
    display: inline-block;
    border-radius: 50%;
    border: .25em solid #000;
    border-right-color: transparent;
    width: 2rem;
    height: 2rem;
    animation: 0.75s linear animate-spinner-border;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}
.spinner-border--mr-1 {
    margin-right: 1rem;
}
.spinner-border--ml-1 {
    margin-left: 1rem;
}
.spinner-border--size-sm {
    width: 1rem;
    height: 1rem;
    border-width: .2em;
}
.spinner-border--color-white {
    border-color: white;
    border-right-color: transparent;
}
.spinner-border--color-blue {
    border-color: #2929e9;
    border-right-color: transparent;
}

@keyframes animate-spinner-border {
    to {
        transform: rotate(360deg);
    }
}
</style>
