export default {
    // Auth|Registration messages
    'E-mail or password entered incorrectly': 'E-mail or password entered incorrectly',
    'Successful authentication': 'Successful authentication',
    'Successful authorization': 'Successful authorization',
    'Enter password': 'Enter password',
    'Passwords don\'t match': 'Passwords don\'t match',
    'Passwords match': 'Passwords match',
    'Repeat password': 'Repeat password',
    'The user has been successfully created': 'The user has been successfully created',
    'Sorry, the user was not created': 'Sorry, the user was not created',
    'A user with this email address already exists': 'A user with this email address already exists',
    'Access and Refresh tokens have been successfully received': 'Access and Refresh tokens have been successfully received',
    'An error occurred while receiving Access and Refresh tokens': 'An error occurred while receiving Access and Refresh tokens',
    'Fill in the Name field': 'Fill in the Name field',
    'Fill in the Surname field': 'Fill in the Surname field',
    'Fill in the E-mail field': 'Fill in the E-mail field',
    'Fill in the Password field': 'Fill in the Password field',
    'Fill in the Confirm password field': 'Fill in the Confirm password field',
    'Enter E-mail address': 'Enter E-mail address',
    'E-mail entered correctly': 'E-mail entered correctly',
    'E-mail entered incorrectly': 'E-mail entered incorrectly',
    'The password is too simple. Use at least 6 characters': 'The password is too simple. Use at least 6 characters',
    'Password strong': 'Password strong',
    'The password is easy to guess. Use lower and upper case letters, numbers and special characters': "The password is easy to guess.\r\nUse lower and upper case letters, numbers and special characters:\r\n!@#$%^&?*()_:;.,-=+/|\\[]{}",

    // Other fields
    'Auth - ButaGo Account': 'Sign in — ButaGo Account',
    'Reg - ButaGo Account': 'Registration — ButaGo Account',
    'Access - ButaGo Account': 'Access recovery — ButaGo Account',
    'Registration in ButaGo': 'Registration in ButaGo',
    'Sign in with ButaGo': 'Sign in with ButaGo',
    'Access recovery': 'Access recovery',
    'Name': 'Name',
    'Surname': 'Surname',
    'E-mail': 'E-mail',
    'Password': 'Password',
    'Confirm password': 'Confirm password',
    'Sign in': 'Sign in',
    'Sign in account': 'Sign in account',
    'Create an account': 'Create an account',
    'Verify account': 'Verify account',
    'Forgot your password': 'Forgot your password',
    'Back Search': 'Back Search',
    'Account successfully verified': 'Account successfully verified',
    'Confirmation code': 'Confirmation code',
    'Send code': 'Send code',
    'Send': 'Send',
    'New password': 'New password',
    'New password confirmation': 'New password confirmation',
    'Password has been successfully changed': 'Password has been successfully changed',
    'Check code': 'Check code',
    'Update password': 'Update password',
    'You can get a new code in N seconds': 'You can get a new code in <span>:timerSecond</span> :wordSecond',
    '1 second': 'second',
    '2 second': 'seconds',
    '5 second': 'seconds',
    'Privacy policy': 'privacy policy',
    'Terms': 'terms',
    'Links privacy policy and terms of use': 'By clicking the <b>:create_account</b> button you agree to our :link_privacy_policy and :link_terms of use',

}