export default {
    // Auth|Registration messages
    'E-mail or password entered incorrectly': 'E-mail или пароль введены неверно',
    'Successful authentication': 'Успешная аутентификация',
    'Successful authorization': 'Успешная авторизация',
    'Enter password': 'Введите пароль',
    'Passwords don\'t match': 'Пароли не совпадают',
    'Passwords match': 'Пароли совпадают',
    'Repeat password': 'Повторите пароль',
    'The user has been successfully created': 'Пользователь был успешно создан',
    'Sorry, the user was not created': 'Извините, пользователь не был создан',
    'A user with this email address already exists': 'Пользователь с таким адресом электронной почты уже существует',
    'Access and Refresh tokens have been successfully received': 'Access и Refresh токены успешно получены',
    'An error occurred while receiving Access and Refresh tokens': 'Произошла ошибка при получении Access и Refresh токенов',
    'Fill in the Name field': 'Заполните поле Имя',
    'Fill in the Surname field': 'Заполните поле Фамилия',
    'Fill in the E-mail field': 'Заполните поле E-mail',
    'Fill in the Password field': 'Заполните поле Пароль',
    'Fill in the Confirm password field': 'Заполните поле Подтвердить пароль',
    'Enter E-mail address': 'Введите E-mail адрес',
    'E-mail entered correctly': 'E-mail введен корректно',
    'E-mail entered incorrectly': 'E-mail введен некорректно',
    'The password is too simple. Use at least 6 characters': 'Пароль слишком простой. Используйте хотя бы 6 символов',
    'Password strong': 'Пароль надежный',
    'The password is easy to guess. Use lower and upper case letters, numbers and special characters': "Пароль легко подобрать.\r\nИспользуйте буквы в нижнем и верхнем регистре,\r\nцифры и спец. символы: !@#$%^&?*()_:;.,-=+/|\\[]{}",

    // Other fields
    'Auth - ButaGo Account': 'Вход — ButaGo Аккаунт',
    'Reg - ButaGo Account': 'Регистрация — ButaGo Аккаунт',
    'Access - ButaGo Account': 'Восстановление доступа — ButaGo Аккаунт',
    'Registration in ButaGo': 'Регистрация в ButaGo',
    'Sign in with ButaGo': 'Войдите с ButaGo',
    'Access recovery': 'Восстановление доступа',
    'Name': 'Имя',
    'Surname': 'Фамилия',
    'E-mail': 'E-mail',
    'Password': 'Пароль',
    'Confirm password': 'Подтвердить пароль',
    'Sign in': 'Войти',
    'Sign in account': 'Войти в аккаунт',
    'Create an account': 'Создать аккаунт',
    'Verify account': 'Подтвердить аккаунт',
    'Forgot your password': 'Забыли пароль',
    'Back Search': 'В поиск',
    'Account successfully verified': 'Аккаунт успешно подтвержден',
    'Confirmation code': 'Код подтверждения',
    'Send code': 'Отправить код',
    'Send': 'Отправить',
    'New password': 'Новый пароль',
    'New password confirmation': 'Подтверждение нового пароля',
    'Password has been successfully changed': 'Пароль был успешно изменен',
    'Check code': 'Проверить код',
    'Update password': 'Обновить пароль',
    'You can get a new code in N seconds': 'Получить новый код можно через <span>:timerSecond</span> :wordSecond',
    '1 second': 'секунду',
    '2 second': 'секунды',
    '5 second': 'секунд',
    'Privacy policy': 'политикой конфиденциальности',
    'Terms': 'условиями использования',
    'Links privacy policy and terms of use': 'Нажимая кнопку <b>:create_account</b> вы соглашаетесь с нашей :link_privacy_policy и :link_terms',
    
}