export default {
    // Auth|Registration messages
    'E-mail or password entered incorrectly': 'E-poçt və ya parol səhv daxil edilib',
    'Successful authentication': 'Uğurlu autentifikasiya',
    'Successful authorization': 'Uğurlu icazə',
    'Enter password': 'Parolu daxil edin',
    'Passwords don\'t match': 'Parollar uyğun gəlmir',
    'Passwords match': 'Parollar uyğun gəlir',
    'Repeat password': 'Parolu təkrarlayın',
    'The user has been successfully created': 'İstifadəçi uğurla yaradıldı',
    'Sorry, the user was not created': 'Üzr istəyirik, istifadəçi yaradılmayıb',
    'A user with this email address already exists': 'Bu e-poçt ünvanı olan istifadəçi artıq mövcuddur',
    'Access and Refresh tokens have been successfully received': 'Access və Refresh tokenləri uğurla qəbul edildi',
    'An error occurred while receiving Access and Refresh tokens': 'Access və Refresh tokenlərini əldə edərkən xəta baş verdi',
    'Fill in the Name field': 'Ad sahəsini doldurun',
    'Fill in the Surname field': 'Soyad sahəsini doldurun',
    'Fill in the E-mail field': 'E-poçt sahəsini doldurun',
    'Fill in the Password field': 'Şifrə sahəsini doldurun',
    'Fill in the Confirm password field': 'Şifrə təsdiqi sahəsini doldurun',
    'Enter E-mail address': 'E-poçt ünvanını daxil edin',
    'E-mail entered correctly': 'E-poçt düzgün daxil edildi',
    'E-mail entered incorrectly': 'E-poçt səhv daxil edildi',
    'The password is too simple. Use at least 6 characters': 'Şifrə çox sadədir. Ən azı 6 simvol istifadə edin',
    'Password strong': 'Şifrə güclü',
    'The password is easy to guess. Use lower and upper case letters, numbers and special characters': 'Şifrəni tapmaq asandır.\r\nKiçik və böyük hərflərdən, rəqəmlərdən və xüsusi\r\nsimvollardan istifadə edin: !@#$%^&?*()_:;.,-=+/|\\[]{}',

    // Other fields
    'Auth - ButaGo Account': 'Giriş — ButaGo Hesab',
    'Reg - ButaGo Account': 'Qeydiyyat — ButaGo Hesab',
    'Access - ButaGo Account': 'Bərpaya giriş — ButaGo Hesab',
    'Registration in ButaGo': 'ButaGo-da qeydiyyat',
    'Sign in with ButaGo': 'ButaGo ilə daxil olun',
    'Access recovery': 'Bərpaya giriş',
    'Name': 'Ad',
    'Surname': 'Soyad',
    'E-mail': 'E-poçt',
    'Password': 'Şifrə',
    'Confirm password': 'Şifrəni təsdiqləyin',
    'Sign in': 'Giriş',
    'Sign in account': 'Hesabınıza daxil olun',
    'Create an account': 'Hesab yarat',
    'Verify account': 'Hesabı doğrulayın',
    'Forgot your password': 'Şifrəni unutmusan',
    'Back Search': 'Axtarışa',
    'Account successfully verified': 'Hesab uğurla təsdiqləndi',
    'Confirmation code': 'Təsdiq kodu',
    'Send code': 'Kodu göndər',
    'Send': 'Göndər',
    'New password': 'Yeni şifrə',
    'New password confirmation': 'Yeni şifrə təsdiqi',
    'Password has been successfully changed': 'Şifrə uğurla dəyişdirildi',
    'Check code': 'Kodu yoxlayın',
    'Update password': 'Şifrəni yeniləyin',
    'You can get a new code in N seconds': '<span>:timerSecond</span> :wordSecond ərzində yeni kodu əldə edə bilərsiniz',
    '1 second': 'saniyəyə',
    '2 second': 'saniyə',
    '5 second': 'saniyə',
    'Privacy policy': 'məxfilik siyasətimiz',
    'Terms': 'istifadə şərtlərimizlə',
    'Links privacy policy and terms of use': '<b>:create_account</b> düyməsini klikləməklə, :link_privacy_policy və :link_terms razılaşırsınız',
    
}